(function () {
	'use strict';
	angular
		.module('sources')
		.controller('SourcesPopoverCtrl', ['$scope', SourcesPopoverCtrl])
		.controller('SchedulesPopoverCtrl', [
			'$scope',
			'seedcodeCalendar',
			'manageSchedules',
			'utilities',
			SchedulesPopoverCtrl,
		])
		.controller('SourcesCtrl', [
			'$scope',
			'$rootScope',
			'$location',
			'seedcodeCalendar',
			'manageSchedules',
			'manageEventSources',
			'manageConfig',
			'utilities',
			'dataStore',
			'environment',
			SourcesCtrl,
		]);

	function SourcesPopoverCtrl($scope) {
		$scope.deauthorize = deauthorize;
		$scope.switchAccount = switchAccount;

		function deauthorize(sourceTemplate, callback) {
			$scope.loading = true;
			$scope.popover.config.show = false;
			sourceTemplate.deauthFunction(
				function () {
					$scope.loading = false;
					if (callback) {
						callback();
					}
				},
				false,
				sourceTemplate
			);
		}

		function switchAccount(sourceTemplate, callback) {
			$scope.loading = true;
			$scope.popover.config.show = false;
			sourceTemplate.switchAccount(function () {
				$scope.loading = false;
				if (callback) {
					callback();
				}
			}, sourceTemplate);
		}
	}

	function SchedulesPopoverCtrl(
		$scope,
		seedcodeCalendar,
		manageSchedules,
		utilities
	) {
		$scope.resources = getResources();

		$scope.colorFormat = $scope.schedule.backgroundColor;
		$scope.updateSource = function (operation, scheduleObj) {
			if (!scheduleObj.name) {
				return;
			}

			manageSchedules.updateSchedule(
				operation,
				scheduleObj,
				successCallback
			);
			$scope.popover.config.show = false; // Hide the popover

			function successCallback() {
				seedcodeCalendar.get('element').fullCalendar('refetchEvents');
			}
		};

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		function getResources() {
			var config = seedcodeCalendar.get('config');
			var resources = seedcodeCalendar.get('resources');
			var result = [];
			for (var i = 0; i < resources.length; i++) {
				if (
					!resources[i].isFolder &&
					resources[i].name !== config.noFilterLabel
				) {
					result.push(resources[i]);
				}
			}
			return result;
		}
	}

	function SourcesCtrl(
		$scope,
		$rootScope,
		$location,
		seedcodeCalendar,
		manageSchedules,
		manageEventSources,
		manageConfig,
		utilities,
		dataStore,
		environment
	) {
		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');
		$scope.isShare = $scope.calendar.config.isShare;
		$scope.isAdmin = $scope.calendar.config.admin; // Setting this explicitely because our template has a repeat with a calendar object. We should fix these names

		// Load the schedules (these get converted to a folder hierarchy)
		$scope.schedules = manageSchedules.createSourceFolders(
			seedcodeCalendar.get('schedules')
		);

		$scope.$on('schedules', function (e, schedules) {
			$scope.$evalAsync(function () {
				$scope.schedules = manageSchedules.createSourceFolders(
					seedcodeCalendar.get('schedules')
				);
			});
		});

		$scope.isFileMakerWebViewer = utilities.getDBKPlatform() === 'dbkfm';

		$scope.createCalendarClasses = function (schedule) {
			const sourceTypeClass = utilities.stringToClass(
				schedule.sourceTemplate.name,
				'dbk-st'
			);
			const calendarClass = utilities.stringToClass(
				schedule.name,
				'dbk-cl'
			);
			return `${sourceTypeClass} ${calendarClass}`;
		};

		$scope.collapseCalendars = function (e, scheduleItem) {
			e.stopImmediatePropagation();

			manageSchedules.toggleCalendarFolder(scheduleItem);

			//emit addScroll event so we adjust the scroll bar when opening and closing the filter folder
			$scope.$emit('addScroll');
		};

		//Select a schedule (source)
		$scope.selectSchedule = function (e, schedule) {
			//Check to make sure that this wasn't triggered from clicking on the settings child element as event bubbling will kick this.
			if ($(e.target)[0] === $(e.currentTarget).find('.fa-cog')[0]) {
				return;
			}
			manageSchedules.selectSchedule(schedule);
		};

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		$scope.isSalesforce = fbk.isSalesforce();

		$scope.authFunction = authFunction;

		$scope.addSource = addSource;

		$scope.loadedTemplates = sourcesLoading();

		$scope.eventSourceExists = eventSourceExists();

		$scope.sourceIsEditable = function (id) {
			return true;
		};

		$scope.addSchedule = function (sourceTypeID) {
			var path = '/settings/source/' + sourceTypeID;

			$location
				.path(path)
				.search('action', 'create')
				.search('entity', 'calendar');
		};

		//See if the item is the last in the schedule / source list
		$scope.lastScheduleItem = function (schedules, index) {
			var checkIndex = index + 1;
			if (
				checkIndex === schedules.length ||
				(schedules[checkIndex] && schedules[checkIndex].isFolder)
			) {
				return true;
			}
		};

		//Clicking on the calendar settings gear
		$scope.sourceSettings = function (e, calendar) {
			var config = seedcodeCalendar.get('config');
			e.stopPropagation();
			if (config.isShare) {
				return;
			}

			// if ($scope.isAdmin) {
			// 	var path = '/settings/source/' + calendar.sourceTypeID + '/' + utilities.stringToID(calendar.id);
			// 	$location.path( path );
			// 	return;
			// }

			if (config.preventPopover) {
				config.preventPopover = false;
				return;
			}

			var template = 'source-settings';
			var source = {
				id: calendar.id,
				sourceTypeID: calendar.sourceTypeID,
				name: calendar.name,
				sourceTemplate: calendar.sourceTemplate,
			};

			var popover = {
				controller: 'SourcesPopoverCtrl',
				target: e.currentTarget,
				container: environment.isPhone
					? $('.filters-popover-container')
					: $('body'),
				type: 'popover', // modal or popover
				destroyOnScroll: environment.isPhone ? false : true,
				direction: 'auto',
				// width: 250,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: source,
				dataTitle: 'source',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/sidebar/sources/' +
					template +
					'.html\'"></div>'
			);
		};

		//Clicking on the calendar settings gear
		$scope.scheduleSettings = function (e, calendar) {
			var config = seedcodeCalendar.get('config');
			if (config.isShare) {
				return;
			}

			if ($scope.isAdmin) {
				var path =
					'/settings/source/' +
					calendar.sourceTypeID +
					'/' +
					utilities.stringToID(calendar.id);
				$location.path(path);
				return;
			}

			if (config.preventPopover) {
				config.preventPopover = false;
				return;
			}

			var template = $scope.isAdmin
				? 'schedule-settings-admin'
				: 'schedule-settings-user';
			var schedule = {
				id: calendar.id,
				sourceTypeID: calendar.sourceTypeID,
				name: calendar.name,
				colorType:
					calendar.source && calendar.source.colorType
						? calendar.source.colorType
						: null,
				backgroundColor:
					calendar.backgroundColor ||
					seedcodeCalendar.get('config').defaultEventColor,
				isPrimary: calendar.isPrimary,
				primaryCalendarOverride:
					seedcodeCalendar.get('config').primaryCalendarOverride ===
					calendar.id,
				editable: calendar.editable,
				enableUserDefaultResource: calendar.enableUserDefaultResource,
				originalDefaultResource: calendar.originalDefaultResource,
				defaultResource: calendar.defaultResource
					? calendar.defaultResource.id
					: null,
				userDefaultResource: calendar.userDefaultResource
					? calendar.userDefaultResource.id
					: null,
			};

			var popover = {
				controller: 'SchedulesPopoverCtrl',
				target: e.currentTarget,
				container: environment.isPhone
					? $('.filters-popover-container')
					: $('body'),
				type: 'popover', // modal or popover
				destroyOnScroll: environment.isPhone ? false : true,
				direction: 'auto',
				width: 275,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: schedule,
				dataTitle: 'schedule',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: function () {
					$rootScope.$broadcast('addScroll');
				},
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/sidebar/sources/' +
					template +
					'.html\'"></div>'
			);
		};

		function authFunction(
			sourceTemplate,
			source,
			statusOnly,
			forceConsent,
			callback
		) {
			var apiConnection = sourceTemplate.getApiConnection
				? sourceTemplate.getApiConnection()
				: null;

			if (apiConnection && apiConnection.authIsRedirect) {
				// If using an auth redirect function (ie a FM card window) then don't set this
				// as we don't know when that window is closed
				sourceTemplate.status.isAuthing = false;
			} else {
				sourceTemplate.status.isAuthing = true;
			}
			sourceTemplate.authFunction(
				authCallback,
				source,
				statusOnly,
				forceConsent,
				null //sourceTemplate.localSchedules
			);
			function authCallback(result) {
				$scope.$evalAsync(function () {
					sourceTemplate.status.isAuthing = null;
					if (callback) {
						callback(result);
					}
				});
			}
		}

		function addSource(sourceTemplate, hasSource) {
			var source = manageConfig.addSource(
				sourceTemplate.id,
				!hasSource && sourceTemplate.localSchedules
			);

			//Run onboarding function for source if there is one
			if (sourceTemplate.onboarding) {
				// Pass methods for working with returned data
				sourceTemplate.onboarding({
					defaultSources: addDefaultSources,
				});
			}

			// $scope.settings.viewedSources =
			// 	manageConfig.transformSources(sourceType);

			if (sourceTemplate.seperateSchedules && !hasSource) {
				//Check if we are authed into source
				authFunction(
					sourceTemplate,
					source,
					null,
					true,
					updateSchedules
				);
			}

			function updateSchedules(auth) {
				// Do something after schedules are updated
				// Load the schedules (these get converted to a folder hierarchy)
				$scope.schedules = manageSchedules.createSourceFolders(
					seedcodeCalendar.get('schedules')
				);
			}

			// Will take an object of sources and add them as new sources
			// Useful for onboarding purposes when sources should be added on first auth
			function addDefaultSources(onboardingSources) {
				if (!hasSource && onboardingSources) {
					// Create new onboarding sources
					for (var property in onboardingSources) {
						manageConfig.addSource(
							sourceTemplate.id,
							false,
							onboardingSources[property]
						);
					}
				}
			}
		}

		function eventSourceExists() {
			var eventSources = seedcodeCalendar.get('sources');
			return eventSources.length;
		}

		function sourcesLoading() {
			var sources = seedcodeCalendar.get('sources');
			var sourceTemplate;
			var loading = [];
			checkSources: for (var i = 0; i < sources.length; i++) {
				sourceTemplate = manageEventSources.getTemplate(
					sources[i].sourceTypeID
				);
				if (!sourceTemplate) {
					continue;
				}
				for (var ii = 0; ii < loading.length; ii++) {
					if (sourceTemplate.id === loading[ii].id) {
						continue checkSources;
					}
				}

				loading.push(sourceTemplate);
			}
			return loading;
		}
	}
})();
